'use strict';

import Swiper, {Navigation, Pagination} from 'swiper';

export function init() {
  Swiper.use([Navigation, Pagination]);

  const $prevButton = '.appointment-stores__option-date .swiper-button-prev',
        $nextButton = '.appointment-stores__option-date .swiper-button-next',
        $prevButtonIcon = '.appointment-stores__option-date .swiper-button-prev .iconfont-arrow';

  var swiperSlider = new Swiper('.appointment-timeslots-slider', {
    noSwiping: true,
    noSwipingSelector: '.appointment-stores__option-days',
    spaceBetween: 8,
    loop: false,
    slidesPerView: 3.8,
    slidesPerGroup: 3,
    touchRatio: 1,
    slidesOffsetAfter: 80,
    navigation: {
        nextEl: $nextButton,
        prevEl: $prevButton,
    },
    breakpoints: {
        640: {
            slidesPerView: 5,
        },
        1025: {
            slidesPerView: 'auto',
            spaceBetween: 0,
            navigation: {
                nextEl: '',
                prevEl: '',
            },
        },
    },
    on: {
        init: function () {
            // If prev week is not empty
            if ( !$($prevButton).hasClass('disabled') ) {
                $($prevButton).removeClass('swiper-button-disabled');
                $($prevButton).removeAttr('disabled');
                $($prevButton).addClass('beginning-reached');
            }

            // Check for 'theme-eam' class and update slidesPerView only on mobile
            if (window.innerWidth <= 786 && $('.theme-eam').length) {
                this.params.slidesPerView = 4;
                this.update();
            }

            // show selected store as first on mobile or tablet
            if(window.innerWidth <= 1024){
                var selectedElement = $('.swiper-slide.is-selected');
                var selectedIndex = this.slides.findIndex(function (slide) {
                    return $(slide).is(selectedElement);
                });
                this.slideTo(selectedIndex);
            }

        },
        fromEdge: function () {
            // Enable prev button when swiping
            $($prevButton).removeClass('disabled');

            // Ensure end hasn't been reached
            if ( !$($nextButton).hasClass('end-reached') ) {
                $($prevButtonIcon).removeClass('disabled');
            }
        },
        reachBeginning: function() {
            // Disable prev button when reaching the beginning and prev week is empty
            if ( !$($prevButton).hasClass('has-prev-week') ) {
                $($prevButton).addClass('disabled');
                $($prevButtonIcon).addClass('disabled');
            }
        },
        slideNextTransitionStart: function () {
            // Remove class when swiping
            if ( $($prevButton).hasClass('beginning-reached') ) {
                $($prevButton).removeClass('beginning-reached');
            }
        },
        slideNextTransitionEnd: function () {
            // Enable next button when reaching end
            if ( $($nextButton).hasClass('swiper-button-disabled') ) {
                $($nextButton).removeClass('swiper-button-disabled');
                $($nextButton).removeAttr('disabled');
                $($nextButton).addClass('end-reached');
            }
        },
        slidePrevTransitionStart: function () {
            // Remove classes when swiping back
            if ( $($nextButton).hasClass('end-reached') ) {
                $($nextButton).removeClass('end-reached');
            }

            if ( $($prevButton).hasClass('beginning-reached') ) {
                $($prevButton).removeClass('beginning-reached');
            }
        },
        slidePrevTransitionEnd: function () {
            // Add class when reaching beginning
            if ( !$($prevButton).hasClass('beginning-reached') ) {
                $($prevButton).addClass('beginning-reached');
            }

            // Enable prev button when prev week is not empty
            if ( $($prevButton).hasClass('has-prev-week') ) {
                $($prevButton).removeClass('swiper-button-disabled');
                $($prevButton).removeAttr('disabled');
            }
        }
    }
  });
}

