'use strict';

/* globals google */

var markerClusterer = require('@googlemaps/markerclusterer');
var { mapStyles } = require('./mapStyles');
var searchHelper = require('./customStoreLocator');
var util = require('../util/util');
var markerCluster;
var markers;
var isTriggeredClusterClick = false;
// variables for autocomplete
var isMobileOrTablet = window.matchMedia("only screen and (max-width: 1024px)").matches;
const STORE_RESULTS_CONTAINER_SELECTOR = '[data-js="store-locator-results"]';
const STORE_SEARCH_FORM_SELECTOR = isMobileOrTablet ? '[data-js="appointment-stores-form-mobile"]' : '[data-js="appointment-stores-form"]';
var countryCode = $(STORE_RESULTS_CONTAINER_SELECTOR).data('search-key').countryCode;
var map;

function focusMarkers(map, activeMarkers, fitToBounds, isSpecificStoreSearch) {
    if (!map || !activeMarkers || activeMarkers.length < 1) {
        return;
    }

    if (activeMarkers.length === 1 || isSpecificStoreSearch) {
        map.setCenter(markers[0].getPosition());
        return;
    }

    var bounds = new google.maps.LatLngBounds();

    activeMarkers.forEach(marker => {
        bounds.extend(marker.getPosition());
    });

    if (fitToBounds) {
        map.fitBounds(bounds);
    }
}

module.exports = {
    initGoogleMap: function (markerClickCallback, fitToBounds, isMyLocationSearch) {

        if (typeof google === 'undefined') {
            return;
        }

        var infowindow = new google.maps.InfoWindow();

        // Init NL or BE Map in the center of the viewport
        var searchKeys = $('[data-js="store-locator-results"]').data('search-key');
        var latlng = new google.maps.LatLng(searchKeys.lat, searchKeys.long);

        // Customized google map marker icon with svg format
        // eslint-disable-next-line block-scoped-var
        var markerImg = {
            path: 'M10,0c5.5,0,10,4.5,10,10s-4.5,10-10,10S0,15.5,0,10S4.5,0,10,0z',
            fillColor: '#000',
            fillOpacity: 1,
            scale: 1.1,
            borderRadius: '50%',
            strokeColor: 'white',
            strokeWeight: 2,
            anchor: new google.maps.Point(13, 30),
            labelOrigin: new google.maps.Point(12, 12)
        };

        var mapdiv = $('[data-js="map-canvas"]').attr('data-locations');
        var mapZoom = parseInt($('[data-js="map-canvas"]').attr('data-mapzoom'), 10) || 8;
        mapdiv = JSON.parse(mapdiv);

        // if my location search was triggered but no results found - we still want to zoom in customers location
        if (mapdiv && mapdiv.length === 0 && isMyLocationSearch) {
            mapZoom = 12;
        }

        var isSpecificStoreSearch = mapdiv[0].isSpecificStoreSearch;

        // if we present multiple markers, customize the zoom level (no more single markers)
        if (mapdiv && isSpecificStoreSearch) {
            mapZoom = mapdiv[0].specificStoreZoomLevel;
        }

        var mapOptions = {
            scrollwheel: false,
            zoom: mapZoom,
            center: latlng,
            fullscreenControl: false,
            styles: mapStyles
        };

        map = new google.maps.Map($('[data-js="map-canvas"]')[0], mapOptions);

        markers = Object.keys(mapdiv).map(function (key) {
            var item = mapdiv[key];
            var label = parseInt(key, 10) + 1;
            var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
            var marker = new google.maps.Marker({
                position: storeLocation,
                map: map,
                title: item.name,
                icon: markerImg,
                label: { text: label.toString(), color: 'white', fontSize: '0' },
                id: item.id
            });

            marker.addListener('click', function (e) {
                // eslint-disable-next-line array-callback-return
                var selectedMarkerCluster = markerCluster.clusters.some(function (cluster) {
                    return marker.id === cluster.marker.id;
                });

                if (!selectedMarkerCluster) {
                    markerCluster.clusters.some(function (cluster) {
                        var clusterMarkersIds = cluster.markers.map(function (clusterMarker) {
                            return clusterMarker.id;
                        });
                        if (clusterMarkersIds.indexOf(marker.id) > -1) {
                            isTriggeredClusterClick = true;
                            google.maps.event.trigger(cluster.marker, 'click');
                            return true;
                        }
                        return false;
                    });
                }

                if (item.infoWindowHtml.length > 0) {
                    infowindow.setOptions({
                        content: item.infoWindowHtml
                    });
                    infowindow.open(map, marker);
                }

                if (typeof markerClickCallback === 'function') {
                    markerClickCallback(marker.id, item.postalCode, true);
                }

                // gtm event start
                if (!e.isTriggeredFromMarker) {
                    $(document).trigger('gtm:storeFinder', {
                        location: marker.title,
                        activityType: 'chose'
                    });
                }
                // gtm event end

                // used in Checkout flow
                // TODO: possible refactoring - move this function to the markerClickCallback call
                google.maps.event.addListener(infowindow, 'domready', function () {
                    if ($('.storelocator__search').find('input[name=isCheckout]').val() === 'true') {
                        $('[data-js="map-canvas"]').find('[data-js=map-store-selection]').off('click').on('click', function (e) {
                            e.preventDefault();
                            var storeId = $(this).closest('.store-details').data('store-id');
                            $('[data-js=selectedStore]').val(storeId);
                            var storeName = $('[data-store-id="' + storeId + '"] > div.store__name').text();
                            var storeAddress = $('[data-store-id="' + storeId + '"] > address > .address').text();
                            $('[data-js=selectedStore]').attr('data-store-name', storeName);
                            $('[data-js=selectedStore]').attr('data-store-address', storeAddress);

                            $('[data-js=selected-store-info] > [data-js=store-name]').text(storeName);
                            $('[data-js=selected-store-info] > [data-js=store-address]').text(storeAddress);
                            $('[data-js="modal-close"]').click();
                            $('[data-js=checkout-shipping-store-selection]').addClass('is-selected');
                            $('.js-edit-store').removeClass('is-hidden');
                            $('.js-select-store').addClass('is-hidden');
                            // gtm event start
                            $(document).trigger('gtm:storeFinder', {
                                location: $(this).data('store-name'),
                                activityType: 'chose'
                            });
                            // gtm event end
                        });
                    }
                });
            });

            return marker;
        });

        focusMarkers(map, markers, fitToBounds, isSpecificStoreSearch);

        var clusterImg = {
            path: 'M10,0c5.5,0,10,4.5,10,10s-4.5,10-10,10S0,15.5,0,10S4.5,0,10,0z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            scale: 2,
            strokeColor: '#dbdbdb',
            strokeWeight: 1,
            anchor: new google.maps.Point(13, 30),
            labelOrigin: new google.maps.Point(10, 10)
        };

        var renderer = {
            render({ count, position }) {
                return new google.maps.Marker({
                    label: { text: String(count), color: 'black', fontSize: '12px' },
                    position,
                    icon: clusterImg,
                    title: '',
                    // adjust zIndex to be above other markers
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
                });
            }
        };

        // eslint-disable-next-line no-shadow
        var onClusterClick = function (event, cluster, map) {
            map.fitBounds(cluster.bounds);
            if (isTriggeredClusterClick) {
                isTriggeredClusterClick = false;
                setTimeout(() => {
                    map.setZoom(12);
                }, 1000);
            }
        };

        markerCluster = new markerClusterer.MarkerClusterer({
            map, markers, renderer, onClusterClick
        });
    },
    selectMarker: function (storeId, isTriggeredFromMarker) {
        if (typeof google === 'undefined') {
            return;
        }

        markers.forEach(function (marker) {
            if (marker.id === storeId) {
                google.maps.event.trigger(marker, 'click', {'isTriggeredFromMarker': isTriggeredFromMarker});
            }
        });
    },
    initAutocomplete: function () {
        var input;
        if ($('[data-js="search-store-location"]').length > 0) {
            input = $('[data-js="search-store-location"]')[0];
        } else if (isMobileOrTablet) {
            input = $('[data-js="appointment-stores-search-mobile"]')[0];
        } else {
            input = $('[data-js="appointment-stores-search"]')[0];
        }
        const options = {
            fields: ['address_components', 'geometry'],
            types: ['locality', 'sublocality', 'postal_code', 'political', 'street_address'],
            strictBounds: false,
            componentRestrictions: {
                country: countryCode
            }
        };

        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.bindTo('bounds', map);
        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                return;
            }
            var addressComponents = place.address_components;
            var addressSearch = util.postalCodeOrCity(addressComponents, countryCode);
            $('[data-postal=api-search-result]').val(addressSearch);
            if ($('[data-js="search-store-location"]').length > 0) {
                searchHelper.storeSearch();
            } else if ($('[data-js="tryinstore-container"]').length > 0) {
                $('[data-js="tryinstore-container"]').find(STORE_SEARCH_FORM_SELECTOR).trigger('submit');
            } else if ($('[data-js="appointment-container"]').length > 0) {
                $('[data-js="appointment-container"]').find(STORE_SEARCH_FORM_SELECTOR).trigger('submit');
            };
        });
    }
};
